
import { Tag } from '@/models/Interfaces';
import { reactive, onMounted } from 'vue';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';

export default {
    name: 'Tags',
    setup() {
        const { t } = useI18n();

        const tags: Tag[] = [];
        const state = reactive({
            tags,
            inputTagName: '',
            inputSubTagName: ''
        });

        const tagVmodel = '';
        const subTagVmodel = '';
        const tagChange = false;
        const subTagChange = false;

        async function getAllTags() {
            swal.showLoading();
            const response = await api.getAllTags();
            if (response.errorMessage) swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
            else if (response.data) {
                swal.close();
                state.tags = response.data;
            }
        }

        async function createTag(name: string) {
            const response = await api.createTag(name);
            if (response.errorMessage) swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
            else if (response.data) {
                swal.close();
                state.tags.push(response.data);
                state.inputTagName = '';
            }
        }

        async function editTag(tagId: string, name: string, tIndex: number) {
            const response = await api.editTag(tagId, name);
            if (response.errorMessage) swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
            else if (response.data) {
                swal.close();
                state.tags[tIndex].name == response.data.name;
            }
        }

        async function deleteTag(id: string, index: number) {
            const response = await api.deleteTag(id);
            if (response.errorMessage) swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
            else if (response.data) {
                swal.close();
                state.tags.splice(index, 1);
            }
        }

        async function createSubTag(tagId: string, name: string, tIndex: number) {
            const response = await api.createSubTag(tagId, name);
            if (response.errorMessage) swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
            else if (response.data) {
                swal.close();
                state.tags[tIndex].subTags.push(response.data);
                state.inputSubTagName = '';
            }
        }

        async function editSubTag(subTagId: string, name: string, tIndex: number, stIndex: number) {
            const response = await api.editSubTag(subTagId, name);
            if (response.errorMessage) swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
            else if (response.data) {
                swal.close();
                state.tags[tIndex].subTags[stIndex].name == response.data.name;
            }
        }

        async function deleteSubTag(id: string, tIndex: number, stIndex: number) {
            const response = await api.deleteSubTag(id);
            if (response.errorMessage) swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
            else if (response.data) {
                swal.close();
                state.tags[tIndex].subTags.splice(stIndex, 1);
            }
        }

        onMounted(() => {
            getAllTags();
        });

        return {
            state,
            tagVmodel,
            subTagVmodel,
            tagChange,
            subTagChange,
            getAllTags,
            createTag,
            createSubTag,
            editTag,
            editSubTag,
            deleteTag,
            deleteSubTag
        };
    }
};
